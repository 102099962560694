.layout {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  display: flex;
}

.layout .layout__header {
  z-index: 2;
  background-color: #fff;
  padding: 6px 16px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.layout .layout__header--gray {
  background-color: #f9f9f9;
}

.layout .layout__body {
  background-color: #f9f9f9;
  flex-grow: 1;
  flex-basis: auto;
  min-width: 0;
  padding-top: 8px;
}

@supports (padding: max(0px)) {
  .layout .layout__body {
    padding-bottom: calc(16px + env(safe-area-inset-bottom, 0px));
  }
}

.layout .layout__body--main {
  background-size: cover;
  padding-top: 0;
}

.layout .layout__body--white {
  background-color: #fff;
}

.layout .layout__body--notifications {
  padding-top: 0;
}

.layout .layout__body--way, .layout .layout__body--events {
  background-color: #fff;
  padding-left: 16px;
  padding-right: 16px;
}

.layout .layout__body--error-placeholder {
  flex-direction: column;
  display: flex;
}

.layout .layout__body--centered {
  flex-direction: column;
  justify-content: center;
  padding-bottom: 128px;
  display: flex;
}
